import { AntdIconProps, Badge, Box, Button } from '@dltru/dfa-ui'
import { FC } from 'react'

interface INotificationsButton {
    count: number
    icon: AntdIconProps
}

export const NotificationsButton: FC<INotificationsButton> = ({ count, icon }) => {
    return (
        <Button type="text" className="antbuttoncontentheader">
            <Box direction="row" justify="space-between" padding={[0, 16]} align="center">
                {icon}
                <Badge count={count} />
            </Box>
        </Button>
    )
}
