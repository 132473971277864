import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { negativeProfileStatuses } from '@dltru/dfa-models'

import { roleFormSelector } from '@store/roleForms/selectors'

import {
    RoleFormContext,
    clientInterests,
    countOfSteps,
    defaultProfile,
    ownInterests,
} from './consts'
import { IInterest, InterestType, ViewState } from './types'

export const RoleFormContextProvider: FC = ({ children }) => {
    const [interest, setInterest] = useState(InterestType.own)
    const [viewState, setViewState] = useState(ViewState.beforeSending)
    const [option, setOption] = useState<IInterest>(ownInterests[0])
    const currentType = useSelector(roleFormSelector.selectProfileType)
    const mainStatus = useSelector(roleFormSelector.selectMainStatus)
    const isRejectStatus = mainStatus && negativeProfileStatuses.includes(mainStatus)

    useEffect(() => {
        ownInterests.forEach((item) => {
            item.disabled = currentType && !isRejectStatus && currentType !== item.typeShort
        })
        clientInterests.forEach((item) => {
            item.disabled = currentType && !isRejectStatus && currentType !== item.typeShort
        })
    }, [currentType, isRejectStatus])

    return (
        <RoleFormContext.Provider
            value={{
                setInterest,
                setOption,
                setViewState,
                ownInterests,
                clientInterests,
                interest,
                viewState,
                option,
                countOfSteps,
                defaultProfile,
            }}
        >
            {children}
        </RoleFormContext.Provider>
    )
}
