import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Box, Button, DownMini, Dropdown, Menu, UserOutlined } from '@dltru/dfa-ui'

import { logout } from '@store/auth'
import { authSelector } from '@store/auth/selectors'

import style from '../style.m.less'

export const PersonalAccountButton: FC = () => {
    const uuid = useSelector(authSelector.selectUserUid)
    const user = useSelector(authSelector.selectUserName)
    const reduxDispatch = useDispatch()
    const logoutHandler = (e: React.MouseEvent) => {
        e.preventDefault()
        reduxDispatch(logout())
    }

    const menu = useMemo(() => {
        if (!uuid) {
            return (
                <Menu.Item key="logout">
                    <a href="/" onClick={logoutHandler}>
                        Выход
                    </a>
                </Menu.Item>
            )
        }
        return (
            <Menu>
                <Menu.Item key="profile">
                    <Link to={`/clients/${uuid}`} state={{ tabKey: 'profile' }}>
                        Профиль
                    </Link>
                </Menu.Item>
                <Menu.Item key="logout">
                    <a href="/" onClick={logoutHandler}>
                        Выход
                    </a>
                </Menu.Item>
            </Menu>
        )
    }, [uuid])

    if (!uuid) {
        return null
    }

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button type="text" className={style.personalButton} width="auto">
                <Box direction="row" justify="space-between" align="center">
                    <UserOutlined />
                    <span>{user}</span>
                    <DownMini />
                </Box>
            </Button>
        </Dropdown>
    )
}
