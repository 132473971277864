import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

import { ActivityDetector, Layout as AntdLayout, DEFAULT_ACTIVITY_EVENTS } from '@dltru/dfa-ui'

import { logout } from '@store/auth'
import { authSelector } from '@store/auth/selectors'

import { RootLoader } from './RootLoader'

export const RootLayout = memo(() => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const sessionTimeout = useSelector(authSelector.selectSessionTimeout)
    const isAuth = useSelector(authSelector.selectIsAuth)
    const isLoading = useSelector(authSelector.selectIsLoading)
    const isAllAgreements = useSelector(authSelector.isAllAgreementsSelector)
    const isUserRegistred = useSelector(authSelector.isUserRegistredSelector)

    useEffect(() => {
        if (isLoading) {
            return
        }

        if (!isAuth) {
            return
        }

        if (!isAllAgreements && isAuth) {
            //if (!location.pathname.startsWith('/update-agreements')) {
            navigate('/update-agreements', { replace: true })
            return
            //}
        }

        if (!isUserRegistred && isAuth) {
            //if (!location.pathname.startsWith('/role-forms')) {
            navigate('/role-forms', { replace: true })
            return
            //}
        }

        // navigate('/offers', { replace: true })
    }, [isAllAgreements, isUserRegistred, isLoading, isAuth])

    if (isLoading) {
        return <RootLoader />
    }

    if (!isLoading && !isAuth) {
        localStorage.removeItem('user')

        // const encodedUrl = encodeURIComponent(window.location.href)
        //  window.location.replace(`/web-auth/start?rd=${encodedUrl}`)
        return <RootLoader />
    }

    return (
        <AntdLayout>
            <Outlet />

            <ActivityDetector
                activityEvents={DEFAULT_ACTIVITY_EVENTS}
                timeToLogout={sessionTimeout}
                logout={() => {
                    dispatch(logout())
                }}
            />
        </AntdLayout>
    )
})
