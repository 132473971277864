import { ContentFooter } from '@dltru/dfa-ui'

import { AuthLiteHeader } from '@components/Header'

import { ContentBox } from './ContentBox'

export const RootLoader = () => {
    return (
        <>
            <AuthLiteHeader />
            <ContentBox type="unauthorized">
                <ContentFooter />
            </ContentBox>
        </>
    )
}
