import cn from 'classnames'
import { FC } from 'react'

import './style.less'

interface IContentBox {
    type?: 'unauthorized' | 'default'
}

export const ContentBox: FC<IContentBox> = ({ children, type = 'default' }) => {
    return (
        <div className={cn('contentbox ', { 'unauthorized-contentbox': type === 'unauthorized' })}>
            {children}
        </div>
    )
}
