export type MenuLink = { key: string; to: string; title: string }
export const MenuLinks = [
    {
        key: 'О проекте',
        to: 'https://www.masterchain.ru/products/digital_assets/',
        title: 'О проекте',
    },
    {
        key: 'О платформе',
        to: 'https://www.masterchain.ru/products/masterchain/',
        title: 'Виды ЦФА',
    },
    { key: 'Об операторе ИС', to: 'https://www.masterchain.ru/about/', title: 'Об операторе ИС' },
] as MenuLink[]
