import { FC } from 'react'

import { Box, Space } from '@dltru/dfa-ui'

import { HeaderLinks, LogoBox, PersonalAccountButton } from '../Header/components'

import { MenuLinks } from './resources'
import style from './style.m.less'

interface IAuthLiteHeaderProps {
    logoLink?: string
}

export const AuthLiteHeader: FC<IAuthLiteHeaderProps> = ({ logoLink }) => {
    return (
        <Box
            className={style.unauthorizedHeader}
            padding={[30, 40]}
            direction="row"
            justify="space-between"
            align="center"
        >
            <LogoBox logoLink={logoLink} />
            <Box direction="row">
                <HeaderLinks links={MenuLinks} />
                <Box direction="row" justify="space-between" align="center">
                    <Space size={16}>
                        <PersonalAccountButton />
                    </Space>
                </Box>
            </Box>
        </Box>
    )
}
