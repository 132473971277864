import { Box } from '@dltru/dfa-ui'
import React from 'react'

import { MenuLink } from '../resources'
import style from '../style.m.less'

interface IHeaderLinks {
    links: MenuLink[]
}

export const HeaderLinks: React.FC<IHeaderLinks> = ({ links }) => {
    return (
        <Box
            className={style.headerLinksBox}
            direction="row"
            align="center"
            justify="space-between"
            margin={[0, 60, 0, 0]}
        >
            {links.length &&
                links.map((link) => (
                    <a key={link.key} href={link.to} rel="noreferrer noopener" target="_blank">
                        {link.title}
                    </a>
                ))}
        </Box>
    )
}
