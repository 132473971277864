import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { ContentFooter } from '@dltru/dfa-ui'

import { AuthLiteHeader } from '@components/Header'

import { ContentBox } from './ContentBox'

interface IPureLayuotProps {
    logoLink?: string
}

export const PureLayout: FC<IPureLayuotProps> = ({ logoLink }) => {
    return (
        <>
            <AuthLiteHeader logoLink={logoLink} />
            <ContentBox type="unauthorized">
                <Outlet />
                <ContentFooter />
            </ContentBox>
        </>
    )
}
