import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Box, MasterchainLogo } from '@dltru/dfa-ui'

import style from '../style.m.less'

interface ILogoBoxProps {
    logoLink?: string
}

export const LogoBox: FC<ILogoBoxProps> = ({ logoLink }) => {
    return (
        <Link to={logoLink || '/'}>
            <Box direction="row">
                <div className={style.logo}>
                    <MasterchainLogo />
                </div>
                <div className={style.logoname}>
                    <span>ЦФА</span>
                </div>
            </Box>
        </Link>
    )
}
