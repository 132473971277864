import { Box, CloseCircleIcon } from '@dltru/dfa-ui'
import { FC } from 'react'

import style from '../style.m.less'

interface IBlockingNotification {
    isUserBlocked?: boolean
}

export const BlockingNotification: FC<IBlockingNotification> = ({ isUserBlocked }) => {
    return (
        <Box className={style.blockingNotificationBox} direction="row" align="center">
            {isUserBlocked && (
                <>
                    <CloseCircleIcon />
                    <span className={style.blockingNotification}>
                        Ваши действия в системе ограничены. Обратитесь в службу поддержки 8 (800)
                        800-80-80
                    </span>
                </>
            )}
        </Box>
    )
}
