import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { ContentFooter } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'

import { AuthLiteHeader } from '@components/Header'

import { ContentBox } from '../ContentBox'

import { RoleFormContextProvider } from './RoleFormContext'

export const RoleFormsLayout: FC = () => {
    const isUserRegistred = useSelector(authSelector.isUserRegistredSelector)

    if (isUserRegistred) {
        return <Navigate to={'/'} replace />
    }

    return (
        <>
            <AuthLiteHeader logoLink={'/role-forms'} />
            <ContentBox type="unauthorized">
                <RoleFormContextProvider>
                    <Outlet />
                </RoleFormContextProvider>

                <ContentFooter />
            </ContentBox>
        </>
    )
}
