import { FC, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { RbacContextProvider } from '@dltru/dfa-rbac'
import { Layout as AntdLayout, ContentFooter } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { loadsetAccountMoneyByUser } from '@store/money'
import { profileDetailsSlice } from '@store/profiles/details'
import { tariffSlice } from '@store/tariff'

import { ContentHeader } from '../Header'
import { Sidebar } from '../Sidebar'

import { ContentBox } from './ContentBox'

export const AppLayout: FC = memo(() => {
    const dispatch = useDispatch()
    const isAllAgreements = useSelector(authSelector.isAllAgreementsSelector)
    const isUserRegistred = useSelector(authSelector.isUserRegistredSelector)

    const scopes = useSelector(authSelector.selectPermissions).join(' ')

    useEffect(() => {
        if (isAllAgreements && isUserRegistred) {
            dispatch(profileDetailsSlice.actions.getQualifyInfo())
            dispatch(tariffSlice.actions.getBaseTariff())
            dispatch(tariffSlice.actions.getFees())
            dispatch(profileDetailsSlice.actions.getProfileDetails())
            dispatch(loadsetAccountMoneyByUser())
        }
    }, [isAllAgreements, isUserRegistred])

    if (!isAllAgreements) {
        return <Navigate to={'/'} replace />
    }

    if (!isUserRegistred) {
        return <Navigate to={'/'} replace />
    }

    return (
        <RbacContextProvider scopes={scopes}>
            <Sidebar />
            <AntdLayout.Content>
                <ContentHeader />
                <ContentBox>
                    <Outlet />
                </ContentBox>
                <ContentFooter />
            </AntdLayout.Content>
        </RbacContextProvider>
    )
})
