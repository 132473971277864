import { PickEnum, ProfileType, ProfileTypeFull } from '@dltru/dfa-models'

export interface IInterest {
    name: string
    label: string
    forTitle: string
    link: string
    type: ProfileTypeFull
    typeShort: TCountOfSteps
    disabled?: boolean
}

export enum ViewState {
    beforeSending = 'beforeSending',
    afterSending = 'afterSending',
}
export enum InterestType {
    own = 'own',
    client = 'client',
}

export type TCountOfSteps = PickEnum<
    ProfileType,
    ProfileType.AGNT | ProfileType.BUSN | ProfileType.LEGL | ProfileType.PRSN
>

export interface IRoleFormContext {
    setInterest: React.Dispatch<React.SetStateAction<InterestType>>
    setOption: React.Dispatch<React.SetStateAction<IInterest>>
    setViewState: React.Dispatch<React.SetStateAction<ViewState>>
    ownInterests: IInterest[]
    clientInterests: IInterest[]
    interest: InterestType
    viewState: ViewState
    option: IInterest
    countOfSteps: Record<TCountOfSteps, number>
    defaultProfile: Record<InterestType, IInterest>
}
