import { Box, Button, Space } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { HeaderLinks, LogoBox } from '../Header/components'
import { MenuLinks } from './resources'
import style from './style.m.less'

export const UnauthorizedHeader: FC = () => {
    const navigate = useNavigate()

    const handleRegistrationRedirect = async () => navigate('/registration')
    const handleLoginRedirect = async () => navigate('/login')

    return (
        <Box
            className={style.unauthorizedHeader}
            padding={[30, 40]}
            direction="row"
            justify="space-between"
            align="center"
        >
            <LogoBox />
            <Box direction="row">
                <HeaderLinks links={MenuLinks} />
                <Box direction="row" justify="space-between" align="center">
                    <Space size={16}>
                        <Button onClick={handleRegistrationRedirect} borderRadius={16} size="large">
                            Регистрация
                        </Button>
                        <Button
                            onClick={handleLoginRedirect}
                            borderRadius={16}
                            size="large"
                            type="primary"
                        >
                            Вход
                        </Button>
                    </Space>
                </Box>
            </Box>
        </Box>
    )
}
