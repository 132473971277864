import React from 'react'

import { ProfileType, ProfileTypes } from '@dltru/dfa-models'

import { IInterest, IRoleFormContext, InterestType, ViewState } from './types'

export const ownInterests: IInterest[] = [
    {
        name: 'individual',
        label: 'Я - физическое лицо',
        forTitle: 'Физическое лицо',
        link: 'individual',
        type: ProfileTypes.PRSN,
        typeShort: ProfileType.PRSN,
    },
    {
        name: 'individual-entrepreneur',
        label: 'Я - индивидуальный предприниматель',
        forTitle: 'Индивидуальный предприниматель',
        link: 'businessman',
        type: ProfileTypes.BUSN,
        typeShort: ProfileType.BUSN,
    },
]

export const clientInterests: IInterest[] = [
    {
        name: 'organization',
        label: 'Организации (юридического лица)',
        forTitle: 'Юридическое лицо',
        link: 'legal-entity',
        type: ProfileTypes.LEGL,
        typeShort: ProfileType.LEGL,
    },
    {
        name: 'individual-entrepreneur',
        label: 'Индивидуального предпринимателя',
        forTitle: 'Индивидуальный предприниматель',
        link: 'businessman',
        type: ProfileTypes.BUSN,
        typeShort: ProfileType.BUSN,
    },
    {
        name: 'individual',
        label: 'Физического лица',
        forTitle: 'Физическое лицо',
        link: 'individual',
        type: ProfileTypes.PRSN,
        typeShort: ProfileType.PRSN,
    },
]

export const countOfSteps = {
    [ProfileType.PRSN]: 5,
    [ProfileType.AGNT]: 2,
    [ProfileType.LEGL]: 11,
    [ProfileType.BUSN]: 9,
}

export const dummy = () => {
    return
}

export const defaultProfile = {
    [InterestType.own]: ownInterests[0],
    [InterestType.client]: clientInterests[0],
}

export const RoleFormContext = React.createContext<IRoleFormContext>({
    setInterest: dummy,
    setOption: dummy,
    setViewState: dummy,
    ownInterests: [],
    clientInterests: [],
    interest: InterestType.own,
    viewState: ViewState.beforeSending,
    option: ownInterests[0],
    countOfSteps,
    defaultProfile,
})
